import axios from 'axios';

export default {
    createBid: function(bidData) {
        // console.log('API has received:', bidData)
        return axios.post('/api/bid', bidData)
    },
    createFloor: function(floorData) {
        // console.log('API has received:', floorData)
        return axios.post('/api/floor', floorData)
    },
    sendErrorEmail: function(bidData) {
        return axios.post('/api/errorEmail', bidData)
    },
    createOperator: function(operatorData) {
        return axios.post('/api/createOperator', operatorData)
    },
}