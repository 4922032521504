import React, { useState, useEffect } from 'react';
import { Typography, createTheme, TextField, Button, Backdrop, Box, Modal, Fade, Slider, styled, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import dustyLogo from '../images/dusty-logo.png';
import moment from 'moment';

import API from '../utils/API';

const OperatorsForm = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: '', email: '', company: '', companyType: '',
        startDate: new Date(), trainingDuration: 1, instructorName: '', otherInstructor: '', promoterScore: 0
    })
    const [disabled, setDisabled] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        // console.log('hi')
        let namePlaceholder = formData.name.slice()
        let emailPlaceholder = formData.email.slice()
        let companyPlaceholder = formData.company.slice()
        let companyTypePlaceholder = formData.companyType.slice()
        let startDatePlaceholder = formData.startDate.toString().slice()
        let instructorPlaceholder;
        if (formData.instructorName == 'Other') {
            instructorPlaceholder = formData.otherInstructor.slice()
        } else {
            instructorPlaceholder = formData.instructorName.slice()
        }

        // console.log('right heree', startDatePlaceholder)
        // let trainingDurationPlaceholder = formData.trainingDuration.slice()
        // if (formData.name !== "" && formData.email !== "" && formData.company !== "" && formData.trainingDuration !== "") {
        if (namePlaceholder.replace(/\s/g, "") && emailPlaceholder.replace(/\s/g, "") && companyPlaceholder.replace(/\s/g, "") && companyTypePlaceholder.replace(/\s/g, "") && formData.trainingDuration !== '' && startDatePlaceholder !== 'Invalid Date' && instructorPlaceholder.replace(/\s/g, "")) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [formData])

    const handleTextChange = (e) => {
        let tempArr = formData
        tempArr[e.target.name] = e.target.value
        setFormData({...tempArr})
    }

    const handleChange = (key, value) => {
        // console.log(key, value)
        // let temp_state = [...formData]
        // let temp_element = { ...temp_state[index]}
        // temp_element[key] = value
        // temp_state[index] = temp_element;
        setFormData({...formData, startDate: value})
    }

    const handleTypeChange = (key, value) => {
        // console.log(value)
        setFormData({...formData, companyType: value.target.value})
    }
    const handleInstructorChange = (key, value) => {
        // console.log(value)
        setFormData({...formData, instructorName: value.target.value})
    }

    const handleSubmit = () => {
        console.log(formData)

        let theInstructorName;
        if (formData.instructorName == "Other") {
            theInstructorName = formData.otherInstructor
        } else {
            theInstructorName = formData.instructorName
        }
        setDisabled(true)

        if (parseInt(formData.trainingDuration) < 1) {
            alert("Your training duration must be at least one day.")
            setDisabled(false)
        } else {
            // console.log('good')
            API.createOperator({name: formData.name, email: formData.email, company: formData.company, companyType: formData.companyType, startDate: moment(formData.startDate).format('M/D/YYYY'), trainingDuration: formData.trainingDuration, instructorName: theInstructorName, promoterScore: formData.promoterScore})
                .then(res => {
                    console.log('SUCCESS', res)
                    setError(false)
                    setOpenModal(true)
                    setDisabled(false)
                })
                .catch(err => {
                    console.log('FAIL', err)
                    setError(true)
                    setOpenModal(true)
                    setDisabled(false)
                })
        }
    }

    return (
        <>
        <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
                <img src={dustyLogo} className={classes.logo}/>
                <Typography className={classes.header} variant="h1">Dusty Robotics</Typography>
                <Typography className={classes.subheader} variant="h1">Operators Form</Typography>
            </div>
            <div className={classes.basicContainer}>
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Name</Typography>
                    <SpecialTextField
                        name="name"
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        // onChange={onChange}
                        onChange={(e) => handleTextChange(e)}
                    />
                </div>
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Email or Phone</Typography>
                    <SpecialTextField
                        name="email"
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        // onChange={onChange}
                        onChange={(e) => handleTextChange(e)}
                    />
                </div>
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Company</Typography>
                    <SpecialTextField
                        name="company"
                        variant="outlined"
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        // onChange={onChange}
                        onChange={(e) => handleTextChange(e)}
                    />
                </div>
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Company Type</Typography>
                    <SpecialFormControl className={classes.dropdown}>
                        <Select
                            value={formData.companyType}
                            onChange={(newType) => handleTypeChange('companyType', newType)}
                        >
                            <MenuItem value={'Framing/Drywall'}>Framing/Drywall</MenuItem>
                            <MenuItem value={'Mechanical'}>Mechanical</MenuItem>
                            <MenuItem value={'Plumbing'}>Plumbing</MenuItem>
                            <MenuItem value={'Electrical'}>Electrical</MenuItem>
                            <MenuItem value={'Concrete'}>Concrete</MenuItem>
                            <MenuItem value={'Fire'}>Fire</MenuItem>
                            <MenuItem value={'General Contractor'}>General Contractor</MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                    </SpecialFormControl>
                </div>
            </div>
            <div>
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Training Start Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            inputFormat="M/d/yyyy"
                            // mask="_/_/____"
                            // inputVariant="outlined"
                            // className={classes.textField}
                            value={formData.startDate}
                            onChange={(newDate) => handleChange('date', newDate)}
                            renderInput={(params) => <SpecialTextField className={classes.textField} variant="outlined" {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Training Duration (days)</Typography>
                    <SpecialTextField
                        name="trainingDuration"
                        variant="outlined"
                        className={classes.textField}
                        type="number"
                        defaultValue={formData.trainingDuration}
                        onWheel={(e) => e.target.blur()}
                        InputLabelProps={{ shrink: true }}
                        // onChange={onChange}
                        onChange={(e) => handleTextChange(e)}
                    />
                </div>
                <br />
                <div className={classes.textFieldItem} align="left">
                    <Typography className={classes.label}>Instructor Name</Typography>
                    { formData.instructorName !== "Other" ? (
                        <SpecialFormControl className={classes.dropdown}>
                            <Select
                                value={formData.instructorName}
                                onChange={(newInstr) => handleInstructorChange('instructorName', newInstr)}
                            >
                                <MenuItem value={'Bashar'}>Bashar</MenuItem>
                                <MenuItem value={'Daniel'}>Daniel</MenuItem>
                                <MenuItem value={'Ethan'}>Ethan</MenuItem>
                                <MenuItem value={'Luisa'}>Luisa</MenuItem>
                                <MenuItem value={'Marshall'}>Marshall</MenuItem>
                                <MenuItem value={'Richard'}>Richard</MenuItem>
                                <MenuItem value={'Scott'}>Scott</MenuItem>
                                <MenuItem value={'Will'}>Will</MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem>
                            </Select>
                        </SpecialFormControl>
                    ) : (
                        <SpecialTextField
                            name="otherInstructor"
                            variant="outlined"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            // onChange={onChange}
                            onChange={(e) => handleTextChange(e)}
                        />
                    )}
                </div>
            </div>
            <div className={classes.sliderContainer}>
                <Typography className={classes.label}>How likely are you to recommend Dusty Robotics?</Typography>
                <SpecialSlider
                    aria-label="Temperature"
                    defaultValue={formData.promoterScore}
                    // getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={10}
                    // marks
                    min={-100}
                    max={100}
                    name="promoterScore"
                    onChange={(e) => handleTextChange(e)}
                    className={classes.slideBar}
                />
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography className={classes.sliderLabel}>-100</Typography>
                    {/* <Typography className={classes.sliderLabel}>0</Typography> */}
                    <Typography className={classes.sliderLabel}>+100</Typography>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography className={classes.sliderLabel}>Not likely</Typography>
                    <Typography className={classes.sliderLabel}>Very likely</Typography>
                </div>
            </div>
            
            <div className={classes.submitContainer}>
                {/* <Typography className={classes.summaryText}>Total: {formData.redSpot + formData.redAC + formData.redSolvent + formData.blackF0L + formData.blackSolvent + formData.blueSpot + formData.blueCyan + formData.blueSolvent} inks, {formData.windguards} windguards, and {formData.wipes} wipes.</Typography> */}
                <button className={classes.submitBtn} onClick={() => handleSubmit()} disabled={disabled}>SUBMIT</button>
            </div>
        </div>


        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
            <Box sx={modalStyle}>
                { error ? (
                    <>
                        <ErrorIcon style={{marginBottom: '13px', color: 'red', fontSize: '35px', }}/>
                        <Typography style={{marginBottom: '13px', fontSize: '20px'}}>An error has occurred..</Typography>
                        <Typography style={{marginBottom: '13px', fontSize: '15px'}}>We apologize for the inconvenience, please try again later.</Typography>
                    </>
                ) : (
                    <>
                        <CheckCircleIcon style={{marginBottom: '13px', color: 'green', fontSize: '35px', }}/>
                        <Typography style={{marginBottom: '13px', fontSize: '20px'}}>Thank you for your response!</Typography>
                        <Typography style={{marginBottom: '13px', fontSize: '15px'}}>Your information has been recorded and will be under review shortly.</Typography>
                    </>
                )}

                {/* <Typography style={{marginBottom: '13px'}}>Thank you for your response!</Typography> */}
                {/* <Typography>Name: {formData.name}</Typography>
                <Typography>Email/phone: {formData.email}</Typography>
                <Typography>Company: {formData.company}</Typography>
                { Object.keys(formData).map((key, index) => {
                    if (formData[key] > 0) {
                        console.log(key, formData[key])
                        return (
                            <>
                            <Typography>{key}</Typography>
                            <Typography>{formData[key]}</Typography>
                            </>
                        )
                    }
                })} */}
            </Box>
            </Fade>
        </Modal>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        // backgroundColor: 'gray',
        marginLeft: '10vw',
        marginRight: '10vw',
        paddingBottom: '10vh',
    },
    headerContainer: {
        paddingTop: '10vh'
    },
    logo: {
        height: '90px',
    },
    header: {
        marginTop: '5px !important',
        color: 'white',
        fontSize: "60px !important",
        fontWeight: "300 !important"
    },
    subheader: {
        color: 'white',
        fontSize: "30px !important",
        fontWeight: "300 !important",
        // marginBottom: '20px !important',
    },
    basicContainer: {
        // marginLeft: '0px !important'
        // backgroundColor: 'gray',
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        justifyContent: 'center',
        maxWidth: '700px',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '30px',
        '@media (max-width:824px)': {
            // marginBottom: '0px',
        },
        '@media (max-width:843px)': {
            justifyContent: 'center',
        },
    },
    textFieldItem: {
        // backgroundColor: 'gray',
        width: 'auto',
        // display: 'flex',
        justifyContent: 'start',
        display: 'inline-block',
        marginLeft: '15px',
        marginRight: '15px',
        // margin: '10px',
        marginTop: '10px',
    },
    label: {
        color: 'white',
        // fontWeight: "300 !important",
    },
    textField: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '5px',
        maxWidth: '190px',
    },
    dropdown: {
        minWidth: '190px !important',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '5px',
    },
    sectionTitle: {
        color: 'white',
        fontSize: "30px !important",
        fontWeight: "300 !important",
        marginTop: '50px !important',
        marginBottom: '20px !important',
    },
    sliderContainer: {
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '35px',
    },
    // slideBar: {
    //     // backgroundColor: 'red',
    // },
    sliderLabel: {
        color: 'white',
        fontSize: '13px !important',
    },
    submitContainer: {
        marginTop: '20px',
    },
    summaryText: {
        color: 'white',
        fontSize: "15px !important",
        fontWeight: "300 !important",
    },
    submitBtn: {
        border: 'none',
        backgroundColor: '#f6862a',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        fontFamily: 'Roboto',
        fontWeight: "500 !important",
        letterSpacing: '1px',
        marginTop: '10px',
        cursor: 'pointer',
        transitionDuration: '0.2s',
        "&:disabled": {
            backgroundColor: 'rgba(128, 128, 128, 0.4)',
            color: '#9c9c9c',
            cursor: 'auto',
        }
    },
}))

const SpecialTextField = withStyles({
    root: {
        '& label': {
            color: 'black',
            backgroundColor: 'white',
            padding: '1px',
            paddingLeft: '3px',
            paddingRight: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '1px',
            paddingLeft: '3px',
            paddingRight: '3px',
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                // backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(TextField);

const SpecialFormControl = withStyles({
    root: {
        '& label': {
            color: 'black',
            backgroundColor: 'white',
            padding: '1px',
            paddingLeft: '3px',
            paddingRight: '3px',
            borderRadius: '4px',
        },
        '& label.Mui-focused': {
            color: 'black',
            backgroundColor: 'orange',
            padding: '1px',
            paddingLeft: '3px',
            paddingRight: '3px',
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                // backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(246, 134, 42, 1)',
            },
        },
    },
})(FormControl);

const SpecialButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(246, 134, 42, 0.85)'
      },
      '&.Mui-disabled': {
        color: 'gray',
        borderColor: 'gray',
        backgroundColor: 'rgba(255, 255, 255, 0.15)'
      }
    },
}))(Button);


const SpecialSlider = styled(Slider)({
    color: '#f6862a',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: 'white',
        border: '2px solid #f6862a',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#f6862a',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
        transform: 'rotate(45deg)',
        },
    },
})


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default OperatorsForm
